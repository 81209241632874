import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Board`}</h1>
    <h6>{`2021-Current`}</h6>
    <ul>
      <li parentName="ul">{`Steve Bowden`}</li>
      <li parentName="ul">{`Anita Cooney`}</li>
      <li parentName="ul">{`Peter Hall`}</li>
      <li parentName="ul">{`Margo Halverson`}</li>
      <li parentName="ul">{`Nick G Liadis`}</li>
      <li parentName="ul">{`Jimmy Luu (Board President)`}</li>
      <li parentName="ul">{`Sandie Maxa (Board Secretary)`}</li>
      <li parentName="ul">{`Kimmie Parker (Board Treasurer)`}</li>
      <li parentName="ul">{`Mark Sanders (Board Vice President)`}</li>
      <li parentName="ul">{`Tricia Treacy`}</li>
    </ul>
    <h6>{`Honorary Board Member`}</h6>
    <ul>
      <li parentName="ul">{`Alison Hildreth		`}</li>
    </ul>
    <h6>{`Aide-de-Camp`}</h6>
    <ul>
      <li parentName="ul">{`Charles Melcher		`}</li>
    </ul>
    <h6>{`Former`}</h6>
    <ul>
      <li parentName="ul">{`Ron Botting (2006-2010)`}</li>
      <li parentName="ul">{`Ben Van Dyke (2008-2017) `}</li>
      <li parentName="ul">{`Elliott Earls (2006-2014) `}</li>
      <li parentName="ul">{`Gabrielle Esperdy	(2009-2020)	`}</li>
      <li parentName="ul">{`Nori Gale (2006-2007)              	              `}</li>
      <li parentName="ul">{`Denise Gonzales Crisp (2011-2017)	`}</li>
      <li parentName="ul">{`Emily Luce (2010-2020)	`}</li>
      <li parentName="ul">{`Melle Hammer (2006-2011)`}</li>
      <li parentName="ul">{`Rachele Riley (2013-2015)			`}</li>
      <li parentName="ul">{`Douglass Scott (2006-2011)`}</li>
      <li parentName="ul">{`Joshua Singer (2013-2017)              	`}</li>
      <li parentName="ul">{`Matt Soar (2007-2008)              	     `}</li>
      <li parentName="ul">{`Gail Swanlund (2011-2020)					    		`}</li>
      <li parentName="ul">{`Andrew Twigg (2007-2014)`}</li>
      <li parentName="ul">{`Paula Volent (2006-2012)		`}</li>
    </ul>
    <h6>{`Founders`}</h6>
    <ul>
      <li parentName="ul">{`Peter Hall`}</li>
      <li parentName="ul">{`Margo Halverson`}</li>
      <li parentName="ul">{`Melle Hammer`}</li>
    </ul>
    <h3>{`Bios`}</h3>
    <p><strong parentName="p">{`Steve Bowden`}</strong>{` explores the mixing and misuse of old and new technologies. He frequently collaborates with other artists, producing interactive events that often ask participants to interact with mundane and spectacular objects and operations. Shortly after graduating from Maine College of Art in 1997. Steve left the US for a residency at Benneton’s Fabrica to co-found their new Media Department in Treviso Italy in and launched COLORS Magazine online in 1998. He then moved to the Lower East Side. Steve worked and taught around NYC and his work with The Chopping Block (for Phish, MoMa and Miramax) was included in the National Design Triennial In 2003. After the dot-bomb, he re-entered academia to earn his MFA from Cranbrook Academy of Art in 2D Design. Upon graduating in 2005, his thesis work was selected for the Daimler Chrysler Emerging Artists Award for 2D and he moved to Brooklyn. He was then hired by a few Cranbrook Alum to create the identity, packaging and textiles for millions of neoprene BUILT bags that can be found anywhere from the Whitney store to your liquor store. Upon arrival of his second child his family returned to New England and he began working for Timberland where he became the Design Manager of Global Interactive and led the digital brand for a few years. He worked like this till he was debt-free and then re-re-entered the academic world to try Adjuncting in 2011. Since, Steve has continues to design, teach and exhibit internationally and currently lives in the woods of NH.`}</p>
    <p><strong parentName="p">{`Anita Cooney`}</strong>{` is both a designer and educator who was named Dean of Pratt Institute’s School of Design just last year, a role that presides over the Institute’s Communications Design, Fashion, Industrial Design, and Interior Design departments. For nine years prior, Cooney served as the chair of Pratt’s top-ranked Interior Design Department, all while working for more than 20 years in architecture and design in New York City. Her interests span across many disciplines making her a well-rounded and sought-after designer. A Pratt alumna, Cooney (B.Arch. ’91) also holds a B.A. from Brown University. As a designer with more than twenty years of experience in professional practice, Cooney is Owner/Principal of acoo design llc and has worked at prominent firms, including Takashimaya and Robert AM Stern Architects.`}</p>
    <p><strong parentName="p">{`Peter Hall`}</strong>{` is a design writer whose research focuses on critical visualisation and mapping as a design process. Peter joined the College from Griffith University Queensland College of Art where he was the program director of the Design and Design Futures program. He teaches design history and methods and have written and lectured widely on mapping as a metaphor for a spatial approach to design criticism and history, including a TEDx appearance, lectures and seminars at Parsons The New School and a video in RMIT’s Design Futures 10 x 10 symposium. Current research includes his involvement in the visualisation work package of the European TREsPASS project, where he is looking into the genealogy and limitations of dominant visualisation tropes such as tree diagrams and the potentials of participatory mapping.`}</p>
    <p>{`Before moving to Griffith, Peter was senior lecturer in design at the University of Texas at Austin where he taught design theory, history, and mapping as a research method. He also taught at Yale University School of Art and worked at the University of Minnesota Design Institute, where he wrote and co-edited with Janet Abrams the book Else/Where: Mapping - New Cartographies of Networks and Territories. He is one of the founders and a board member of DesignInquiry, a non-profit educational organisation devoted to researching design issues at intensive team-based gatherings, based in Maine, USA. He has been a contributing writer for Metropolis magazine and has written widely about design in its various forms for publications including Design and Culture, Design Philosophy Papers, Print, I.D. Magazine, The New York Times, and The Guardian. His books include Tibor Kalman: Perverse Optimist and Sagmeister: Made You Look.`}</p>
    <p><strong parentName="p">{`Margo Halverson`}</strong>{` is co-founder (2001) and board member of DesignInquiry, for which she served as President from 2006 to 2010. Her creative practice integrates photography, graphic design, collaborative partnerships, and teaching, and has garnered national and international accolades. Co-principal of Alice Design Communication, a graphic design studio founded in 1998, Margo and her partner Charles Melcher are prolific designers to businesses and institutions in Portland, ME, and beyond. Margo is also committed to art and design education as a Professor at Maine College of Art since 1991, and as Program Chair of Graphic Design off and on forever. She brings this expertise to non-designers with her tutorial DesignSense for Presentations (Proximity Learning, 1999). Margo holds a B.F.A. and M.F.A. (Photography) from Arizona State University.`}</p>
    <p><strong parentName="p">{`Nick G Liadis`}</strong>{` relies on many disciplines and media in an attempt to make and explain ideas that challenge himself and his own contemporary environment. Trained as an architect—balancing professional practice with teaching—he is also an avian conservation biologist. Nick comes to science having expanded a strong interest in the natural world, and in particular, avian ecology. Much of his work currently is at the interface of architecture and bird studies, helping shape the emerging field of urban avian conservation biology. This unique perspective is defined by a desire to promote avian conservation across the multitude of environments that birds traverse as they migrate, from forests to cities. `}</p>
    <p><strong parentName="p">{`Jimmy Luu’s`}</strong>{` practice resists traditional notions of Graphic Design, resulting in a broad range of activity that investigates the intersection of design and other disciplines. He was a former art director of Ninth Letter literary-arts magazine, and his publication design has been recognized in multiple venues including PRINT magazine and Graphis. His award-winning large-scale public artwork, Letterscape, is part of the City of Austin’s permanent public art collection. He oversees the Department of Visual Studies’  `}<a parentName="p" {...{
        "href": "https://www.risographlab.com/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Risograph Lab`}</a>{`  and serves as the Director of Education and Mentorship on the board of the Austin chapter of the AIGA. Luu is a native Texan, second-generation Vietnamese-American, and an alum of the Land Arts of the American West field study program.`}</p>
    <p><strong parentName="p">{`Sandie Maxa`}</strong>{` is a designer and educator from Minneapolis, where she worked for many years on branding and systems design projects for large corporate clients before moving to New York to start the multi-disciplinary studio Q Collective. As partner, creating work for clients like the AIA Center for Architecture and New Dramatists reflects her deep interest in identity, interactivity and visual culture. She holds a bachelor’s degree in Art-Graphic Design from University of Wisconsin-Madison and an MFA from Virginia Commonwealth University. Sandie is currently on faculty at MICA and Director of the Graphic Design MA program. Previously, she served as adjunct faculty and MFA thesis advisor in the Graduate Communications Design department at Pratt Institute and has taught a variety of studio classes covering design process, experimental typography, interactive design and book design at Parsons The New School for Design and Rutgers University. Sandie is also co-author of the 6th and 7th Editions of Typographic Design: Form and Communication published by Wiley.`}</p>
    <p><strong parentName="p">{`Kimmie Parker`}</strong>{` is an interdisciplinary artist, designer, typeface designer, knitter, and writer in Detroit, Michigan. She is currently an Assistant Professor of graphic design at Oakland University. As a trained artist and graphic designer, her creative work oscillates between fine art-based explorations and more traditional academic pursuits. Her current research questions the role of graphic design tools and techniques in the construction—and deconstruction—of personal identity in our visual culture. She is particularly interested in the ways emerging technologies such as artificial intelligence and algorithmically targeted content affect, manipulate, and skew our sense of self. Kimmie holds a Bachelor of Science from Michigan State University, a Bachelor of Fine Arts from Wayne State University, and a Master of Fine Art in 2D Design from Cranbrook Academy of Art.`}</p>
    <p><strong parentName="p">{`Mark Sanders`}</strong>{` is a designer, teacher, author, typographer, photographer, baker and basket maker. He explores many of these pursuits as a Founder and Creative Director at the transdisciplinary design studio Q Collective, a professor of graphic design at the Maryland Institute College of Art, and co-author of the 6th and 7th editions of Typographic Design: Form and Communication. Mark designs and produces interactive projects, identities, literature systems, promotional items, books, and exhibitions for a diverse group of cultural institutions. His latest work explores interaction design as a folk art pursuit, expressing cultural identity by conveying shared community values and aesthetics through digital interface, architecture, and experience. He facilitates and constructs participatory digital archives that are transient portals into process, presentation, participation, and dissemination.`}</p>
    <p><strong parentName="p">{`Tricia Treacy`}</strong>{` is an interdisciplinary artist and educator raised in an Irish American family whose experimental and collaborative practice intersects print media, design, and publishing into participatory projects. She attempts to blur the boundaries between art and design while extending the notion of a book. Creative research has been presented at venues including Atypi (Hong Kong + Barcelona), the Type Directors Club (New York), Tasmeen biennial Art + Design conference (Doha, Qatar), and TypeCon national conferences. She was awarded the Rome Prize in Design from the American Academy in Rome, a Center for Craft, Creativity and Design Project Grant, and a Women in Design Biennial Special Mention Researchers Award from Association Italian Design of Visual Communication. Her work has been exhibited widely and books are housed in over sixty collections. Tricia is an associate professor of studio art at Dartmouth College in Hanover, NH and holds an M.F.A.(Printmaking) from The University of the Arts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      